import React, { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "@reach/router";

import CardOrderControls from "@/components/pages/platform/pages/CardOrder/CardOrderControls";
import Structure from "./structure";
import TabsBlock from "@/components/pages/platform/pages/CardOrder/tabsBlock";
import OrderDiscussion from "@/components/orderDiscussion";
import Preloader from "@/components/ui-kit/preloader";
import Description from "./description";
import TitleDescription from "./titleDescription";
import DocumentManagement from "../DocumentManagement";
import { Modal, notification } from "antd";

import { AppStateType } from "@/reducers";
import {
  getOrderInfo,
  getOrderSuccess,
  updateOrderStatusFailure,
  updateOrderStatusInfo,
} from "@/actions/orders.actions";
import { IDocument, OrderStatusesType, OrderType } from "app/types";
import { currentOrderSelector } from "@/selectors/currentOrder.selector";

import css from "./cardOrder.modules.scss";
import { navigate } from "gatsby";
import { roleResolver } from "@/utils/roleResolver";

interface ICardOrderPropsType extends RouteComponentProps {
  orderId?: number;
}

export enum ApprovalFactsResults { REQUIRED , APPROVED, REJECTED }

export type AdditionalProductType = {
  productId: number;
  count: number;
  itemPrice: number;
  status?: string;
  label?: string;
  images?: { id: number, link: string, isBase: boolean, image: string }[];
};

export type ApprovalFactType = {
  result: number;
  role: number;
  user: string | null;
  approvalDate: string | null;
}

const statuses: OrderStatusesType[] = [
  {
    name: "Новый заказ",
    status: "new",
    isActive: true,
  },
  {
    name: "В обработке",
    status: "processing",
    isActive: false,
  },
  {
    name: "Согласование изменений",
    status: "updated",
    isActive: false,
  },
  {
    name: "Подготовка КП",
    status: "preparation",
    isActive: false,
  },
  {
    name: "На согласовании КП",
    status: "approval",
    isActive: false,
  },
  {
    name: "Выполнен",
    status: "closed",
    isActive: false,
  },
];

const canceledStatus: OrderStatusesType[] = [
  {
    name: "Отменен",
    status: "cancelled",
    isActive: true,
  },
];

const CardOrder: FC<ICardOrderPropsType> = ({ orderId }) => {
  const [currentOrder, setCurrentOrder] = useState<OrderType>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [additionalProducts, setAdditionalProducts] = useState<AdditionalProductType[]>([]);
  const [activeStatuses, setActiveStatuses] = useState<OrderStatusesType[]>([]);
  const [notActiveStatuses, setNotActiveStatuses] = useState<OrderStatusesType[]>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<IDocument[]>([]);

  const dispatch = useDispatch();

  const {
    order,
    getOrderStatus,
    updatedOrderStatus,
  } = useSelector((state: AppStateType) => state.orders);

  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const { isSupplier, isDemo } = roleResolver(userInfo?.roles);

  const {
    sum,
    prepay,
    discount,
    isProducts,
  } = useSelector(currentOrderSelector);

  const { supplier, contractor } = currentOrder ?? {};

  const contractorName: string = contractor?.id === +userInfo?.contractor_id ? contractor?.name : "";
  const companyName: string = isSupplier ? supplier?.name : contractorName;
  const creatorsName: string = `${userInfo?.name} ${companyName || ""}`.trim();

  useEffect(() => {
    if (orderId) dispatch(getOrderInfo(orderId));

    return () => dispatch(getOrderSuccess(null));
  }, [orderId, updatedOrderStatus]);

  useEffect(() => {
    setCurrentOrder(order);
  }, [order, updatedOrderStatus]);

  useEffect(() => {
    if (updatedOrderStatus) {
      updateDepartmentSuccessNotify();
      dispatch(updateOrderStatusFailure());
    }
  }, [updatedOrderStatus]);

  useEffect(() => {
    if (currentOrder) {
      setActiveStatuses(
        statuses?.slice(1, currentActiveStatusIndex + 1)?.map((status: OrderStatusesType) => {
          return {
            name: status?.name,
            status: status?.status,
            isActive: true,
          };
        }));

      setNotActiveStatuses(statuses?.slice(currentActiveStatusIndex + 1));
    }
  }, [
    currentOrder,
    updatedOrderStatus
  ]);

  useEffect(() => {
    if (getOrderStatus !== 200 && getOrderStatus !== 0) {
      error();
    }
  }, [getOrderStatus]);

  const currentActiveStatus: OrderStatusesType = statuses.filter((status: OrderStatusesType) => status?.status.includes(currentOrder?.status))[0];
  const currentActiveStatusIndex: number = statuses?.lastIndexOf(currentActiveStatus);

  const error = () => {
    Modal.error({
      title: 'Ошибка соединения с сервером',
      content: 'Произошла непредвиденная ошибка. Пожалуйста, попробуйте позже',
      okText: "Вернуться в заказы",
      onOk() { navigate(`/platform/orders/`); },
    });
  };

  const isHiddenSupplierCard: boolean = isSupplier || isDemo;

  const newOrderStatus: OrderStatusesType[] = [{
    name: "Новый заказ",
    status: "new",
    isActive: true,
  }];

  const currentStatuses: OrderStatusesType[] = currentOrder?.status === 'cancelled'
    ? canceledStatus
    : [...newOrderStatus, ...activeStatuses, ...notActiveStatuses];

  const updateDepartmentSuccessNotify = () => {
    notification["success"]({
      message: `Статус заказа: №${orderId} успешно обновлен.`,
    });
  };

  const updateStatus = (): void => {
    dispatch(updateOrderStatusInfo(currentOrder.id));
  };

  const groupedApprovalFactsByRole: Record<number, ApprovalFactType[]> = currentOrder?.approvalFacts?.reduce((acc, current) => {
    if (!acc[current.role]) {
      acc[current.role] = [];
    }

    acc[current.role].push(current);

    return acc;
  }, {});

  // Из-за механизма доработки заказа факты согласования дублируются. Оставляем на основании роли самые последние факты и те, что еще не заполнили
  const latestApprovalFacts: ApprovalFactType[] = groupedApprovalFactsByRole && Object.values(groupedApprovalFactsByRole)?.map(group => {
    const unfilledFact: ApprovalFactType = group?.find(({result}) => result === ApprovalFactsResults.REQUIRED);

    if (unfilledFact) {
      // Если есть незаполненный факт, оставляем только его
      return unfilledFact;
    } else {
      // Если все факты заполнены, оставляем тот, у которого самая последняя дата
      return group?.reduce((latest, current) => {
        return dayjs(current.approvalDate).isAfter(dayjs(latest.approvalDate)) ? current : latest;
      });
    }
  });

  const currentApprovalCount: number = latestApprovalFacts?.filter(({result}) => result === ApprovalFactsResults.APPROVED)?.length;
  const hasCommercialOffer: boolean = uploadedDocuments?.some(({ fileType }) => fileType === "commercial_offer");
  const hasCommercialOfferDisclosure: boolean = uploadedDocuments?.some(({ fileType }) => fileType === "commercial_offer_disclosure");
  const isDisabledUploadCommercialOfferBtn: boolean = !(hasCommercialOffer && hasCommercialOfferDisclosure);

  return (
    <div className="mt-10">
      {currentOrder ? (
        <>
          <TitleDescription
            orderId={currentOrder?.id}
            name=""
            price={currentOrder?.value}
            currency={currentOrder?.currency}
            isHiddenSupplierCard={!isHiddenSupplierCard}
          />
          <TabsBlock
            count={!!latestApprovalFacts?.length && {
              current: currentApprovalCount ?? 0,
              total: latestApprovalFacts?.length ?? 0
            }}
            namesForShowCount={["На согласовании КП"]}
            statuses={currentStatuses}
            updateStatus={updateStatus}
            setActiveStatuses={setActiveStatuses}
            setNotActiveStatuses={setNotActiveStatuses}
            currentActiveStatusIndex={currentActiveStatusIndex}
          />
          <div className="mt-10 tablet:mt-15">
            <div className={css.orderDetailsText}>Детали заказа</div>
            <Description
              isShowWarning={isSupplier && order?.status === "preparation"}
              isEditMode={isEditMode}
              orderSum={currentOrder?.value}
              currency={currentOrder?.currency}
              setCurrentOrder={setCurrentOrder}
              supplierName={currentOrder?.supplier?.name}
              purchaserName={currentOrder?.contractor?.name}
              commercialOffer={currentOrder?.commercialOfferType}
              dateCreated={currentOrder?.createdAt}
              deliveryAddress={currentOrder?.deliveryAddress}
              payType={currentOrder?.paymentType}
              deliveryDate={currentOrder?.deliveryDate}
              deliveryType={currentOrder?.deliveryType}
              msg={currentOrder?.comment}
              prepay={prepay}
            />
            <Structure
              orderId={orderId}
              items={currentOrder?.items}
              setCurrentOrder={setCurrentOrder}
              currency={currentOrder?.currency}
              totalSum={sum}
              totalDiscount={discount}
              isProducts={isProducts}
              isEditMode={isEditMode}
              totalValue={currentOrder?.value}
              diffValue={currentOrder?.diffValue}
              valueByCategory={currentOrder?.valueByCategory}
              additionalProducts={additionalProducts}
              setAdditionalProducts={setAdditionalProducts}
            />
            <CardOrderControls
              order={order}
              orderId={orderId}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              currentOrder={currentOrder}
              setCurrentOrder={setCurrentOrder}
              additionalProducts={additionalProducts}
              setAdditionalProducts={setAdditionalProducts}
              updateStatus={updateStatus}
              creatorsName={creatorsName}
              latestApprovalFacts={latestApprovalFacts}
              isDisabledUploadCommercialOfferBtn={isDisabledUploadCommercialOfferBtn}
            />
          </div>
          <DocumentManagement
            orderId={currentOrder?.id}
            setUploadedDocuments={(uploadedDocuments) => setUploadedDocuments(uploadedDocuments)}
          />
          <OrderDiscussion order={currentOrder} creatorsName={creatorsName} />
        </>
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default CardOrder;
